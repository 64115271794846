/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import ScrollToTop from "../scrolltop"
import "../header.css";
import { jobData } from "../jobdata/jobdata";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import FormComp from "./form";




const ApplyJobs  = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [ params_id, setPramasId ] = useState();
    const [ data, setData ] = useState();
    const [ isModal, setModal ] = useState(false);

    //modal box
   

    const FetchParamsValue = () => {
        let p_data = searchParams.get('p_id');
        setPramasId(p_data);
    }

    const GetData = () => {
           jobData.find((c) => {
              if( c.p_id == params_id ) {
                  setData(c)
              }
          })
    }

    useEffect(() => {
        FetchParamsValue()
        GetData();
        console.log('data is :', data);
    },[data])

    return(
       <div>
                <div class="">
                <ScrollToTop/>
                <div class="container-fluid career_img-apply-now text-center  d-flex justify-content-center flex-column">
                    <h2 class="display-5 service-heading text-white mt-5">Careers</h2>
                </div>
                <div class="container-fluid mt-5 mx-5">
                <p className="fifth-h1-3">
                 {
                    params_id == 1 ? <p className="fifth-h1-3"> International Voice Technical/Customer support</p> : <p className="fifth-h1-3"> International Non Voice Technical/Customer support</p>
                 }
                </p>
                <p class="wwe-p px-2 mt-3">
                Location: { 'Chennai, India' } <br></br>
                Eligiblity: Freshers 2019, 2020, 2021, and 2022 are eligible.<br></br>
                </p>
                <p className="fifth-h1-4">
                    JOB Description :
                </p>
                {
                    params_id == 1 ?  <p class="wwe-p px-2 mt-1">
                    * Candidates should have Excellent English communication skills.<br></br>
                    * Freshers 2019, 2020, 2021, and 2022 are eligible.<br></br>
                    * Experience up to 5 years in international voice process Customer/ Technical support are eligible.<br></br>
                    * Shift Timings : Night shift / Should be flexible to work in any rotational shift. <br></br>
                    * Diploma/ any graduates can apply.<br></br>
                    * Immediate joiners only and Notice period of 15 days can apply.
                    </p> : 
                     <p class="wwe-p px-2 mt-1">
                    * Candidates should have Excellent English communication skills.<br></br>
                    * Freshers 2019, 2020, 2021, and 2022 are eligible.<br></br>
                    * Experience up to 5 years in international Non- voice process Customer/ Technical support are eligible.<br></br>
                    * Shift Timings : Night shift / Should be flexible to work in any rotational shift. <br></br>
                    * Diploma/ any graduates can apply.<br></br>
                    * Immediate joiners only and Notice period of 15 days can apply.
                     </p>
                }
                <p class="fifth-h1-4">
                Selection process :
                </p>
                <p class="wwe-p px-2 mt-1">
                HR Interview<br></br>
                SD Panel interview<br></br>
                </p>
                <p class="fifth-h1-4" >
                Eligible candidates kindly contact/Whatsapp @ 9444292275
                </p>
                <button type="submit" class="btn-for-career mt-2" onClick={() => {
                 window.location.href= 'form'
                }}>
                                Apply Now
                    </button>
                    <p class = 'mt-5'> </p>
                </div>
               <FormComp setModal={setModal} isModal={isModal} />
            </div>
           
       </div>
    )
}
export default ApplyJobs